import React, { useState } from 'react';
import Alert from '../components/Alert';

export default function Upload() {
	const [fileInputState, setFileInputState] = useState('');
	const [textInputState, setTextInputState] = useState('');
	const [previewSource, setPreviewSource] = useState('');
	const [selectedFile, setSelectedFile] = useState();
	const [successMsg, setSuccessMsg] = useState('');
	const [errMsg, setErrMsg] = useState('');

	const handleFileInputChange = (e) => {
		console.log(e.target.type)
		if(e.target.type === "text"){
			setTextInputState(e.target.value)
			return 
		}

		const file = e.target.files[0];
		previewFile(file);
		setSelectedFile(file);
		setFileInputState(e.target.value);
	};

	const previewFile = (file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		console.log(file.name)
		reader.onloadend = () => {
			setPreviewSource(file.name);
		};
	};

	const handleSubmitFile = async (e) => {
		e.preventDefault();
		if (!selectedFile) return;

		console.log( textInputState )
		console.log( fileInputState );
		const formData = new FormData();

		formData.append("fileUpload", selectedFile)
		formData.append("chave", textInputState)

		// const url = 'http://localhost:3013/upload'
		const url = 'https://apiv17.diallink.com.br/upload'

		try {
			const response = await fetch(url, {
				method: 'POST',
				body: formData,
			})
			console.log(response.status)

			if (response.status !== 200){

				let res = await  response.json()
				let erroCampos = res.message["erro"]
				console.log(erroCampos)
				let mensagemErro = "Erro na importação, por favor verifique a(s) coluna(s): "
				for(let erroCampo of erroCampos ){

					console.log(erroCampo.campo)

					mensagemErro += `${erroCampo.campo}  `
				}
				console.log(mensagemErro)

				setFileInputState('');
				setErrMsg('Algo deu errado, tente novamente!');
				setPreviewSource(mensagemErro);
			}else{

				setFileInputState('');
				setPreviewSource('Arquivo carregado com sucesso');
				setSuccessMsg('Arquivo carregado com sucesso!');
			}

		} catch (err) {
			console.error(err);
			setErrMsg('Algo deu errado, tente novamente!');
		}
	};

	return (
		<div>
		<h1 className="title">Upload de arquivo</h1>
		<Alert msg={errMsg} type="danger" />
		<Alert msg={successMsg} type="success" />
		<form onSubmit={handleSubmitFile} className="form">
		<div>
		<label>
		Chave de acesso:
		</label>
		<input required type="text" name="chave" onChange={handleFileInputChange} value={textInputState} />
		</div> <br/> <br/>
		<input required
		id="fileInput"
		type="file"
		name="image"
		onChange={handleFileInputChange}
		value={fileInputState}
		className="form-input"
		/>
		<button className="btn" type="submit">
		Submit
		</button>
		</form>
		{previewSource && (
			<h1>
			{previewSource}
			</h1>
		)}
		</div>
	);
}
